define("discourse/plugins/discourse-99designs/discourse-99designs/templates/connectors/user-card-post-names/external-profile-link", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if user.external_profile_url}}
    <a href={{user.external_profile_url}} style="text-decoration:underline">show
      99designs profile</a>
  {{/if}}
  */
  {
    "id": "VUHdUwbr",
    "block": "[[[41,[30,0,[\"user\",\"external_profile_url\"]],[[[1,\"  \"],[44,[[28,[37,2],null,[[\"user\"],[[28,[32,0],[\"user\"],null]]]]],[[[10,3],[15,6,[52,[30,1,[\"user\"]],[28,[30,1,[\"user\"]],null,null],[28,[32,1],[[30,0],\"user.external_profile_url\",\"[\\\"The `user` property path was used in the `discourse/plugins/discourse-99designs/discourse-99designs/templates/connectors/user-card-post-names/external-profile-link.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.user}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]],[14,5,\"text-decoration:underline\"],[12],[1,\"show\\n    99designs profile\"],[13]],[1]]],[1,\"\\n\"]],[]],null],[1,[28,[32,2],[\"[[\\\"The `user` property path was used in the `discourse/plugins/discourse-99designs/discourse-99designs/templates/connectors/user-card-post-names/external-profile-link.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.user}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"if\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-99designs/discourse-99designs/templates/connectors/user-card-post-names/external-profile-link.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});